/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

:root {
  --primary-color: #6f55f2;
  --heading-color: #132742;
  --bg-gradient: linear-gradient(
    90deg,
    rgba(103, 101, 240, 1) 27%,
    rgba(141, 141, 235, 1) 85%
  );
  --font-name: "Questrial", sans-serif;
  
  /* 
  --bg-color: #9785f2;
  --bg-color: #b5a9f3;
  --bg-color: #d3cef2;
  --bg-color: #d7337a;
        #9785f2
        #b5a9f3
        #d3cef2
        #d7337a
    */
}
/* .fit-width{
  overflow-x: hidden;
} */