.gym__hero {
  display: flex;
  padding: 150px 150px 50px 150px;
  justify-content: space-between;
  /* position: relative; */
}
.gym__hero-content h2 {
  color: var(--heading-color);
  font-size: 3rem;
  padding-right: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}
.highlight {
  color: var(--primary-color);
}
.gym__hero-content {
  max-width: 700px;
}
.gym__hero-content p {
  color: var(--heading-color);
  font-size: 1.1rem;
  margin-bottom: 50px;
  line-height: 30px;
}
.gym__hero-btns {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.watch-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 600;
  background: #d3cef2;
  border-radius: 5px;
  padding-right: 5px;
}
.watch-btn span {
  color: var(--primary-color);
  background: #fff;
  padding: 0.8rem;
  border-radius: 50%;
  font-size: 1.3rem;
  box-shadow: 3px 3px 8px -1px #d3cef2;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-01 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 25px solid rgb(141, 141, 235);
}
.box-02 {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 25px solid #b5a9f3;
}
.box-03 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 25px solid #d3cef2;
}
.box-img {
  position: absolute;
  z-index: 2;
  top: -5%;
  right: -10%;
  width: 123%;
}
.gym__hero-img {
  display: flex;
  flex-direction: column;
  position: relative;
}
.gym__hero-img-heart {
  position: absolute;
  background-color: #fff;
  box-shadow: -3px 3px 8px -1px #d3cef2;
  border-radius: 5px;
  padding: 15px 10px;
  width: 100px;
  top: 55%;
  left: -11%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.gym__hero-img-heart span {
  color: #d7337a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 25px;
  background-color: #d3cef2;
  border-radius: 3px;
  padding: 3px;
}
.gym__hero-img-heart h5,
.gym__hero-img-location h5 {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--heading-color);
}
.gym__hero-img-location {
  position: absolute;
  top: -2%;
  right: -17%;
  background-color: #fff;
  box-shadow: -3px 3px 8px -1px #d3cef2;
  border-radius: 5px;
  padding: 1.2rem;
  z-index: 10;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 150px;
  text-align: center;
}
.gym__hero-img-location span {
  border-radius: 50%;
  color: #fff;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}
.gym__hero-img-location h5 {
  font-size: 0.9rem;
}
.gym__hero-img-dumble {
  z-index: 10;
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  background: #d7337a !important;
  top: -2%;
  left: -5%;
}
@media screen and (max-width: 1050px) {
  .gym__hero {
    flex-wrap: wrap;
    gap: 12rem;
    justify-content: center;
    align-items: center;
  }
  .gym__hero-content h2 {
    padding-right: 0px;
  }
}
@media screen and (max-width: 800px) {
  .gym__hero {
    padding: 150px 100px 50px 100px;
  }
}
@media screen and (max-width: 650px) {
  .gym__hero {
    padding: 150px 50px 50px 50px;
  }
  .gym__hero-content h2 {
    color: var(--heading-color);
    font-size: 2.5rem;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .gym__hero-content p {
    font-size: 1rem;
    line-height: 28px;
    margin-bottom: 60px;
  }
  .box-01 {
    width: 350px;
    height: 350px;
  }
  .box-02 {
    width: 300px;
    height: 300px;
  }
  .box-03 {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 500px) {
  .gym__hero-content h2 {
    font-size: 2rem;
  }
  .gym__hero-content p {
    font-size: 0.9rem;
    line-height: 24px;
  }
  .gym__hero-btns .watch-btn {
    font-size: 0.9rem;
  }
  .gym__hero-btns .register-btn {
    font-size: 0.9rem;
  }
  .gym__hero-img-heart h5,
  .gym__hero-img-location h5 {
    font-size: 0.8rem;
  }
  .box-01 {
    width: 300px;
    height: 300px;
  }
  .box-02 {
    width: 250px;
    height: 250px;
  }
  .box-03 {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 400px) {
  .gym__hero-btns .watch-btn,
  .gym__hero-btns .register-btn {
    font-size: 0.7rem;
  }
  .watch-btn{
    gap: 0.5rem;
  }
  .box-01 {
    width: 250px;
    height: 250px;
  }
  .box-02 {
    width: 200px;
    height: 200px;
  }
  .box-03 {
    width: 150px;
    height: 150px;
  }
  .gym__hero-img-heart h5,
  .gym__hero-img-location h5 {
    font-size: 0.6rem;
  }
  .gym__hero-img-heart {
    padding: 8px;
    width: 80px;
  }
  .gym__hero-img-location {
    width: 100px;
    padding: 15px;
  }
  .gym__hero-img-location span {
    width: 30px;
    height: 30px;
    /* padding: 1px;
     */
    font-size: 1rem;
  }
  .gym__hero-img-dumble {
    width: 30px !important;
    height: 30px !important;
    padding: 6px !important;
  }
}
@media screen and (max-width: 320px){
.gym__hero-content h2 {
    font-size: 1.8rem;
}
.watch-btn span{
  padding: 0.6rem;
  font-size: 1rem;
  width: 40px;
  height: 40px;

}
}
