* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* body{
  /* overflow-x: hidden; */
body {
  font-family: var(--font-name);
  width: 100%;
  overflow-x: hidden;
}
h1,
h2,
h3 {
  font-weight: 700;
}
section {
  padding: 50px 0;
}
ul {
  list-style: none;
}
