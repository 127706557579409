.gym__pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 150px 150px 50px 150px;
}
.pricing-top {
  text-align: center;
}
.pricing-top h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.pricing-top p {
  max-width: 500px;
  font-size: 1.1rem;
  line-height: 28px;
  margin-bottom: 50px;
  color: var(--heading-color);
}
.pricing-card {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background: #fff;
}
.pricing-card-container {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  /* flex-wrap: wrap-reverse; */
}
.pricing-card-top {
  padding: 30px;
  text-align: center;
  border-bottom: solid 1px #d3cef2;
}
.pricing-card-02 .pricing-card-top {
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px 5px 0 0;
}
.pricing-card-02 .pricing-card-top h2 {
  font-weight: 500;
}
.pricing-card-02 .pricing-card-top h2 span {
  color: #fff;
}
.pricing-card-02 .pricing-card-bottom ul li i {
  color: var(--primary-color);
}
.pricing-card-top h2 {
  font-size: 1.5rem;
  margin-bottom: 0.9rem;
}
.pricing-card-top > :last-child {
  font-size: 1.4rem;
}
.pricing-card-top > :last-child span {
  font-size: 1rem;
  color: #666;
}
.pricing-card-bottom {
  padding: 30px;
}
.pricing-card-bottom ul li {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #666;
  line-height: 30px;
}
.pricing-card-bottom ul li span i {
  font-size: 0.7rem;
}
.pricing-card {
  display: flex;
  flex-direction: column;
}
.pricing-card button {
  margin-top: 50px;
  margin-bottom: 20px;
  align-self: center;
}
@media screen and (max-width: 1280px) {
  .pricing-card-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .pricing-top h2 {
    font-size: 2rem;
  }
  .pricing-top p {
    font-size: 1rem;
    line-height: 24px;
  }
}
@media screen and (max-width: 800px) {
  .gym__pricing {
    padding: 150px 100px 50px 100px;
  }
}
@media screen and (max-width: 650px) {
  .gym__pricing {
    padding: 150px 50px 50px 50px;
  }
  .pricing-top h2 {
    font-size: 1.8rem;
  }
  .pricing-top p {
    font-size: 0.8rem;
  }
  .pricing-card-top h2 {
    font-size: 1.3rem;
  }
  .pricing-card-top > :last-child {
    font-size: 1.1rem;
  }
  .pricing-card-bottom ul li {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 400px) {
.pricing-card-top{
  padding: 30px 20px;
}
.pricing-card-bottom{
  padding: 30px 20px;
}
}
@media screen and (max-width: 350px) {
.pricing-card-top{
  padding: 20px 15px;
}
.pricing-card-bottom{
  padding: 20px 15px;
}
}
@media screen and (max-width: 320px) {
  .pricing-top h2 {
    font-size: 1.6rem;
  }
}
